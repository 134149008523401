* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.loadercontainer {
    width: 10%;         /* Set a width to your container */
    margin-left: auto;  /* Automatically adjust the left margin */
    margin-right: auto; /* Automatically adjust the right margin */
  }

  .eventcontainer {
    max-width: 800px;
    margin: 0 auto;
    padding: 0.5rem;
}


.event-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0px 15px 15px 15px;
  display: grid;
  grid-template-columns: 250px 1fr;
  gap: 10px;
  align-items: start;
  grid-template-columns: repeat(1, 1fr)
}

.cover-photo {
  width: 100%;
  border-radius: 8px;
  object-fit: cover;
  cursor: pointer;
  aspect-ratio: 5/6;
}

.event-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.event-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
}

.event-meta {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  color: #666;
  font-size: 0.9rem;
}

.event-meta-center {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  color: #666;
  font-size: 0.9rem;
  justify-content: center;
}

.actions {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.action-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
  padding: 8px 12px;
  border-radius: 6px;
  transition: all 0.3s;
}

.pagination-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
}

.pagination-btn {
  display: inline-flex;
  align-items: center;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 25px;
  background-color: #8B0000;
  color: white;
  font-family: 'Arial', sans-serif;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.pagination-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  background-color: #800000;
}

.pagination-btn:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.pagination-btn.prev::before {
  content: '← ';
  margin-right: 0.5rem;
}

.pagination-btn.next::after {
  content: ' →';
  margin-left: 0.5rem;
}

/* Disabled state */
.pagination-btn.disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 0.7;
}

.pagination-btn.disabled:hover {
  transform: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Base styles */
.toggle-btn {
  padding: 0.75rem 0.75rem;
  font-size: 0.5rem;
  font-weight: bolder;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: #e0e0e0;
  color: #333;
  width: fit-content;
}

.toggle-btn:hover {
  background-color: #e0e0e0;
}

.toggle-btn.LIKED {
  background-color: #4CAF50;
  color: white;
}

.toggle-btn.LIKE {
  background-color: #e0e0e0;
  color: #333;
}

.likemodal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s;
}

.like-modal-content {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  width: 90%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.likes-close-btn {
  float: right;
  cursor: pointer;
  font-size: 1.5rem;
  color: #636e72;
}

.likes {
  border-radius: 0.5rem;
  border: none;
  font-size: 0.7rem;
  font-weight: bolder;
  padding:0.6rem 0.3rem;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}

.comments {
  border-radius: 0.5rem;
  border: none;
  font-size: 0.7rem;
  font-weight: bolder;
  padding:0.6rem 0.3rem;
}

/* Button Styles */
.open-likes-modal-btn {
  padding: 12px 24px;
  background: #0984e3;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.2s;
}

.open-likes-modal-btn:hover {
  background: #0873c4;
}

.like-user-card {
  display: flex;
  align-items: center;
  padding: 1rem;
  margin: 1rem 0;
  background: #f8f9fa;
  border-radius: 8px;
  transition: transform 0.2s;
}

.like-user-card:hover {
  transform: translateY(-2px);
  background: #f1f3f5;
}

.like-user-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
}

.like-user-info {
  flex-grow: 1;
}

.like-user-name {
  margin: 0;
  color: #2d3436;
  font-size: 1.1rem;
}

.like-user-description {
  margin: 0.3rem 0;
  color: #636e72;
  font-size: 0.9rem;
}

.like-user-distance {
  color: #0984e3;
  font-weight: 500;
  font-size: 0.9rem;
}


.modal-overlay {
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: none;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  backdrop-filter: blur(8px);
}

.modal-overlay.active {
  display: flex;
  opacity: 1;
}

.modal-content {
  position: relative;
  background: #fff;
  padding: 30px;
  border-radius: 20px;
  max-width: 90%;
  max-height: 90vh;
  text-align: center;
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.3);
}

.modal-image {
  max-width: 100%;
  max-height: 42vh;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.modal-image:hover {
  transform: scale(1.02);
}

.caption {
  font-size: 1.3em;
  margin-bottom: 20px;
  color: var(--text-dark);
  font-weight: 500;
  letter-spacing: 0.5px;
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background: var(--accent-color);
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.close-btn svg {
  width: 20px;
  height: 20px;
  fill: white;
}

.close-btn:hover {
  transform: rotate(90deg) scale(1.1);
  background: #c0392b;
}

.nav-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.9);
  border: none;
  width: 50px;
  height: 50px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.nav-btn svg {
  width: 24px;
  height: 24px;
  fill: var(--text-dark);
}

.nav-btn:hover {
  background: white;
  transform: translateY(-50%) scale(1.1);
}

.prev-btn {
  left: -25px;
}

.next-btn {
  right: -25px;
}

.progress-indicator {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.3);
  color: white;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 0.9em;
}

.comment-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.comment-modal {
  background-color: white;
  border-radius: 12px;
  width: 60%;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  margin-top: 25vh;
  height: 65%;
}

.comment-modal-header {
  padding: 16px;
  border-bottom: 1px solid #e2e8f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.comment-modal-title {
  font-size: 1rem;
  font-weight: 600;
}

.comment-close-btn {
  background: red;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  color: white;
  padding: 4px;
  width: 10%;
}

.comment-modal-body {
  padding: 16px;
  overflow-y: auto;
  flex-grow: 1;
}

.comment-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #cbd5e1;
  border-radius: 8px;
  resize: vertical;
  min-height: 100px;
  margin-bottom: 16px;
}

.post-btn {
  background-color:darkorange;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  cursor: pointer;
  float: right;
  transition: background-color 0.3s;
}

.post-btn:hover {
  background-color:red
}

.comments-list-div {
  margin-top: 24px;
}

.comment {
  display: flex;
  gap: 12px;
  padding: 16px 0;
  border-bottom: 1px solid #e2e8f0;
}

.comment:last-child {
  border-bottom: none;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.comment-content {
  flex-grow: 1;
}

.comment-header {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 8px;
}

.user-name {
  font-weight: 600;
  color: var(--text-color);
}

.user-description {
  color: #64748b;
  font-size: 14px;
}

.comment-time {
  color: #94a3b8;
  font-size: 12px;
}

.comment-text {
  color: var(--text-color);
  line-height: 1.5;
  margin-bottom: 8px;
}

.delete-btn {
  background: none;
  border: none;
  color: #ef4444;
  cursor: pointer;
  font-size: 14px;
  padding: 4px;
  transition: color 0.3s;
}

.delete-btn:hover {
  color: white;
}

.textareadiv{
  margin-bottom: 10vh;
}

@media (max-width: 768px) {
  .modal-content {
      padding: 20px;
  }
  
  .nav-btn {
      width: 40px;
      height: 40px;
  }

  .prev-btn {
      left: -15px;
  }

  .next-btn {
      right: -15px;
  }

  .caption {
      font-size: 1.1em;
  }
}



/* Hover effects for devices with hover capability */
@media (hover: hover) {
  .toggle-btn:hover {
      transform: scale(1.05);
      box-shadow: 0 2px 8px rgba(0,0,0,0.2);
  }
}

.toggle-btn:active {
  transform: scale(0.95);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .toggle-btn {
      padding: 0.75rem 0.75rem;
      font-size: 0.5rem;
      font-weight:bolder;
  }
}

@media (max-width: 480px) {
  .toggle-btn {
      padding: 0.8rem 0.8rem;
      font-size: 0.5rem;
      border-radius: 0.75rem;
      font-weight:bolder;
  }
}

.likebody {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background-color: #f5f5f5;
}

@media (max-width: 768px) {
  .event-card {
      grid-template-columns: 1fr;
  }

  .pagination-container {
    flex-direction: column;
    align-items: center;
}

.pagination-btn {
    width: 100%;
    justify-content: center;
}
 
}

@media (max-width: 480px) {
  .comment-modal {
      width: 95%;
      margin-top: 25vh;
      height: 65%;
  }
  
  .comment {
      flex-direction: column;
      align-items: start;
  }
}