.body {
    font-family: Arial, sans-serif;
    margin: 0; /* Remove default body margin */
    padding: 0; /* Remove default body padding */
    background-color: #f4f4f4;
    color: #333;
  }

  .logincontainer {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: #FFD166;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .logincontainer h1 {
    text-align: center;
    color: #ff4500; /* Orange */
  }

  label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color:#d98800; /* Red */
  }

  input[type="tel"],
  input[type="number"] {
    width: calc(100% - 22px); /* Adjust width to align with button */
    padding: 10px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }

  button {
    width: 100%;
    padding: 12px;
    background-color: #ff9900; /* Orange */
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }

  button:hover {
    background-color: #ff6347; /* Lighter Orange */
  }

  .error-message {
    color: #ff0000; /* Red */
    font-size: 14px;
    margin-bottom: 16px;
  }

  /* Section Styles */
  .section {
    padding: 20px;
    margin-top: 20px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .section-phone {
    border: 2px solid #ff9900; /* Orange */
    background-color:  rgba(255, 255, 255, 0.9);
  }

  .section-otp {
    border: 2px solid #ff9900; /* Orange */
    background-color:  rgba(255, 255, 255, 0.9);
  }

  /* Align Textbox and Button */
  .input-group {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between textbox and button */
  }

  .input-group input {
    flex: 1; /* Textbox takes remaining space */
    margin-bottom: 0; /* Remove margin-bottom for alignment */
  }

  .input-group button {
    width: auto; /* Button width adjusts to content */
    padding: 10px 20px; /* Adjust padding for better appearance */
  }

  /* Footer Styles */
  footer {
    background-color: #ff4500; /* Orange */
    color: #fff;
    padding: 20px;
    text-align: center;
    width: 100%;
    margin-top: 20px;
  }

  footer a {
    color: #fff;
    text-decoration: none;
    margin: 0 10px;
  }

  footer a:hover {
    text-decoration: underline;
  }

  footer div {
    margin-bottom: 10px;
  }

  .highlight {
    color:orangered;
    font-weight: 600;
}

  /* Responsive Design */
  @media (max-width: 600px) {
    .container {
      margin: 10px;
      padding: 15px;
    }

    h1 {
      font-size: 24px;
    }

    input[type="tel"],
    input[type="number"] {
      font-size: 14px;
    }

    button {
      font-size: 14px;
    }

    .input-group {
      flex-direction: column; /* Stack textbox and button vertically on small screens */
    }

    .input-group button {
      width: 100%; /* Full width for button on small screens */
    }
  }