* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.bodywraper {
    font-family: 'Roboto', sans-serif;
    line-height: 1.6;
    background-color: #f8f9fa;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
}

.hero-section {
    background-color: darkred;
    color: white;
    padding: 4rem 2rem;
    text-align: center;
    margin-bottom: 2rem;
    border-radius: 15px;
}

.hero-section h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
    margin-bottom: 1rem;
    font-weight: 700;
}

.steps-section {
    background-color: #FFD166;
    padding: 2rem;
    border-radius: 15px;
    margin-bottom: 2rem;
}

.step {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 1.5rem;
    margin: 1rem 0;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.faq-section {
    background-color: #4A4A4A;
    color: white;
    padding: 2rem;
    border-radius: 15px;
}

.faq-item {
    background-color: #5E5E5E;
    margin: 1rem 0;
    padding: 1.5rem;
    border-radius: 10px;
    transition: transform 0.3s ease;
}

.faq-item:hover {
    transform: translateX(10px);
}

.faq-question {
    color: #FF6B35;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
}

.highlight {
    color: #FF6B35;
    font-weight: 600;
}

.bot-command {
    color: #EF476F;
    font-weight: 500;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 0.2rem 0.5rem;
    border-radius: 5px;
}

@media (max-width: 768px) {
    .container {
        padding: 1rem;
    }

    .hero-section {
        padding: 2rem 1rem;
    }

    .hero-section h1 {
        font-size: 2rem;
    }

    .steps-section {
        padding: 1rem;
    }
}

.copyright {
    margin-top: 10px;
    flex-basis: 100%;
}