header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #d98800;
    z-index: 10000;
    padding: 10px 20px;
    box-sizing: border-box;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  header > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  header ul {
    display: flex;
    gap: 25px;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  header li {
    margin: 0;
    position: relative;
  }
  
  header a {
    text-decoration: none;
    color: white;
    font-weight: 600;
    padding: 12px 18px;
    border-radius: 6px;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;
    display: inline-block;
    font-size: 1.1rem;
  }
  
  /* Hover effect */
  header a::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 50%;
    background-color: white;
    transition: all 0.3s ease;
  }
  
  header a:hover {
    transform: translateY(-2px);
    color: #fff5e6;
  }
  
  header a:hover::after {
    width: 100%;
    left: 0;
    background-color: #ffd700;
  }
  
  /* Active link styling */
  header a.active {
    color: #fff;
    transform: translateY(-1px);
  }
  
  header a.active::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #ff0000;
    animation: underline-grow 0.3s ease-out;
  }
  
  @keyframes underline-grow {
    from { width: 0; }
    to { width: 100%; }
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    header > div {
      flex-direction: column;
      gap: 5px;
    }
  
    header ul {
      gap: 15px;
    }
  
    header a {
      padding: 10px 14px;
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    header {
      padding: 1px;
    }
  
    header a {
      padding: 8px 12px;
      font-size: 0.9rem;
    }
  }
  
  .main {
    margin-top: 160px;
  }